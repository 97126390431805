import React, { useState } from 'react'
import {
  ActionButton,
  Avatar,
  Button,
  Circle,
  Group,
  HStack,
  Icon,
  Item,
  List,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import FileUploader from '@src/components/Inputs/FileUploader/FileUploader'
import { createDocumentUploadSession, importDocumentsFile } from '@src/api/importData'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { formatFileSize } from '@src/utils/format'
import { getImportFileTemplate } from '@src/api/bulkDataImport'
import { saveFile } from '@src/utils'
import { API } from '@src/constants/api'

const MAX_UPLOADING_FILES_COUNT = 5

type FileState = 'pending' | 'uploading' | 'done' | 'error'

interface FileItemType {
  tempId: number
  file: File
  state: FileState
  error?: string
}

type FileItemProps = Pick<FileItemType, 'file' | 'state' | 'error'>

const FileItem = ({ file, state, error }: FileItemProps) => {
  const description = (() => {
    if (state === 'done') {
      return formatFileSize(file.size)
    }
    if (state === 'pending') {
      return 'Pending...'
    }
    if (state === 'uploading') {
      return 'Uploading...'
    }
    if (state === 'error') {
      const fileWontBeIncludedMessage = 'This file will not be included.'
      return `${fileWontBeIncludedMessage} ${error || 'Error has occurred'}`
    }
    return null
  })()

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon="Document" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{file.name}</Item.Title>
        <Item.Description color={state === 'error' ? Token.color.error : undefined}>
          {description}
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

export const DocumentsUploadFiles = () => {
  const [files, setFiles] = useState<FileItemType[]>([])
  const [sessionId, setSessionId] = useState<number>()
  const [downloadTemplatePending, setDownloadTemplatePending] = useState(false)

  const onDownloadTemplate = async () => {
    setDownloadTemplatePending(true)
    try {
      const { data } = await getImportFileTemplate(`${API.DOCUMENTS}/uploads/bulk`)
      saveFile(data, 'Folders structure')
    } finally {
      setDownloadTemplatePending(false)
    }
  }

  const continueDisabled =
    files.length === 0 ||
    files.some(file => file.state === 'pending' || file.state === 'uploading') ||
    !files.some(file => file.state === 'done')

  if (sessionId) {
    const pendingFiles = files.filter(f => f.state === 'pending')
    const uploadingFiles = files.filter(f => f.state === 'uploading')

    const availableToUploadCount = MAX_UPLOADING_FILES_COUNT - uploadingFiles.length

    const nextToUpload = pendingFiles.slice(0, availableToUploadCount)

    if (nextToUpload.length) {
      const nextToUploadIds = nextToUpload.map(f => f.tempId)

      setFiles(prev =>
        prev.map(file =>
          nextToUploadIds.includes(file.tempId) ? { ...file, state: 'uploading' } : file,
        ),
      )

      nextToUpload.forEach(file => {
        const filePath = file.file.webkitRelativePath?.split('/') || []
        const isValidDirStruct = filePath.length === 3 || filePath.length === 4

        const employeeName = filePath[1]
        let categoryName = ''
        let fileName = ''

        if (filePath.length === 4) {
          categoryName = filePath[2]
          fileName = filePath[3]
        } else if (filePath.length === 3) {
          fileName = filePath[2]
        }
        const formattedName = categoryName
          ? `${employeeName} - ${categoryName} - ${fileName}`
          : `${employeeName} - ${fileName}`

        importDocumentsFile(
          sessionId,
          file.file,
          isValidDirStruct ? formattedName : undefined,
        )
          .then(() => {
            setFiles(prev =>
              prev.map(f => (f.tempId === file.tempId ? { ...f, state: 'done' } : f)),
            )
          })
          .catch(error => {
            const errorMessage =
              error?.response?.data?.detail || error?.response?.data?.file

            setFiles(prev =>
              prev.map(f =>
                f.tempId === file.tempId
                  ? {
                      ...f,
                      state: 'error',
                      error: typeof errorMessage === 'string' ? errorMessage : undefined,
                    }
                  : f,
              ),
            )
          })
      })
    }
  }

  return (
    <>
      <Widget p="s-24">
        <VStack space="s-16">
          <Text fontWeight={600} fontSize="18px">
            Organise your documents by creating employee folders and we automatically
            assign them
          </Text>
          <List use="ol">
            <List.Item
              useIcon={
                <Circle bg={Token.color.foreground} size={24}>
                  <Text color={Token.color.background}>1</Text>
                </Circle>
              }
            >
              <VStack>
                <Text>
                  Create one folder for each employee (name it with their email) or
                  download the auto-created folders below.
                </Text>
                <HStack space="s-6" align="center" mt="s-8">
                  <Text
                    use="div"
                    pr="s-4"
                    color={Token.color.greyTone50}
                    fontWeight={400}
                  >
                    Example:
                  </Text>
                  <Icon name="Profile" color={Token.color.greyTone50} size={15} />
                  <Text color={Token.color.greyTone50} fontWeight={400}>
                    john.wilson@companyname.com
                  </Text>
                </HStack>
                <ActionButton
                  variant="accent"
                  onClick={onDownloadTemplate}
                  pending={downloadTemplatePending}
                  mt="s-16"
                  useIcon="Download"
                >
                  Download folders
                </ActionButton>
              </VStack>
            </List.Item>
            <List.Item
              useIcon={
                <Circle bg={Token.color.foreground} size={24}>
                  <Text color={Token.color.background}>2</Text>
                </Circle>
              }
            >
              Group all the employee’s documents inside each folder
            </List.Item>
            <List.Item
              color={Token.color.greyTone50}
              useIcon={
                <Circle bg={Token.color.foreground} size={24}>
                  <Text color={Token.color.background}>3</Text>
                </Circle>
              }
            >
              <Text>
                Optional: Automatically assign a category by leveraging the auto-created
                sub-folders or create new ones inside each employees folder
              </Text>
              <HStack space="s-6" align="center" mt="s-8">
                <Text use="div" pr="s-4" color={Token.color.greyTone50} fontWeight={400}>
                  Example:
                </Text>
                <Icon name="Profile" color={Token.color.greyTone50} size={15} />
                <Text color={Token.color.greyTone50} fontWeight={400}>
                  john.wilson@company.com
                </Text>
                <Icon name="Services" color={Token.color.greyTone50} size={15} />
                <Text color={Token.color.greyTone50} fontWeight={400}>
                  Contracts
                </Text>
              </HStack>
            </List.Item>
          </List>
          <Group>
            <FileUploader
              multiple
              directory
              showDropAreaImage
              noDropAreaHighlight
              showClip={false}
              attachButtonText="Click to upload directory"
              onChange={async event => {
                if (Array.isArray(event)) {
                  if (!files.length && !sessionId) {
                    const response = await createDocumentUploadSession()
                    setSessionId(response.data.id)
                  }

                  setFiles(prev => [
                    ...event.map(file => ({
                      tempId: Math.random(),
                      file,
                      state: 'pending' as const,
                    })),
                    ...prev,
                  ])
                }
              }}
              accept={
                'image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, text/csv, application/csv, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              bottomInfo="Allowed document formats: .docx, .pdf, .jpeg, .jpg, .png, .xlsx, .csv"
            />
            {files.map(file => (
              <FileItem {...file} key={file.tempId} />
            ))}
          </Group>
        </VStack>
      </Widget>

      <PageActions>
        <Button
          onClick={() =>
            navigateReplace(
              pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION, {
                id: sessionId,
              }),
            )
          }
          disabled={continueDisabled}
          elevated
        >
          Continue
        </Button>
      </PageActions>
    </>
  )
}
