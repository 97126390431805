import React from 'react'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  useGetSpecialisationHiringProcess,
  useGetActiveSpecialisationHiringProcessList,
} from '@src/api/hiringProcess'
import { Button, ItemSkeleton, VStack } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { SpecialisationHiringProcess } from '@src/interfaces/hiringProccess'
import HiringProcessDetails from '@src/components/HiringProcessDetails/HiringProcessDetails'
import { PageActions } from '@src/components/Page/PageActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useLocation, useParams } from 'react-router-dom'
import {
  getLocationDescriptor,
  navigateTo,
  useOpenNewTab,
} from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { JobPostingLocationState } from '@src/features/JobPostingFlow/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import RadioSelectInput, {
  createNewKey,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'

const HiringProcessSelector = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const permissions = useSelector(selectPermissions)
  const canCreate = permissions.includes(PermissionTypes.AddHiringProcess)
  const canEdit = permissions.includes(PermissionTypes.ChangeHiringProcess)
  const { data, isLoading } = useGetActiveSpecialisationHiringProcessList(
    values.specialisation?.id,
  )
  const openNewTab = useOpenNewTab()
  if (!values.hiring_process && data.length) {
    values.hiring_process = data.find(({ is_default }) => is_default)
  }
  const options = data.map(specialisationHiringProcess => ({
    key: specialisationHiringProcess.id,
    label: specialisationHiringProcess.name,
    value: specialisationHiringProcess,
  }))
  const value = options?.find(
    option => values.hiring_process?.id === option.value.id,
  )?.value
  const handleChange = (hiringProcess: SpecialisationHiringProcess | null) => {
    if (hiringProcess) {
      values.hiring_process = hiringProcess
    }
  }
  return (
    <RadioSelectInput
      label="Hiring process"
      loading={isLoading}
      options={options}
      showCreateNewButton={canCreate}
      value={value}
      onChange={val => {
        if (val) {
          if (typeof val?.id === 'string' && val.id === createNewKey) {
            openNewTab(
              pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
                id: values.specialisation?.id,
              }),
            )
          } else {
            handleChange(val)
          }
        }
      }}
      referenceUrl={
        canEdit && values.hiring_process?.id && values.specialisation?.id
          ? getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.GENERAL, {
                hiringProcessId: values.hiring_process.id,
                specialisationId: values.specialisation.id,
              }),
            )
          : undefined
      }
    />
  )
}

export const HiringProcessList = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetSpecialisationHiringProcess(
    values.specialisation?.id,
    values.hiring_process?.id,
  )
  if (isLoading) {
    return (
      <VStack gap="s-8">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </VStack>
    )
  }
  return (
    <HiringProcessDetails
      hiringProcessRounds={data?.hiring_process_stages ?? []}
      loading={isLoading}
    />
  )
}

export const HiringProcess = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  return (
    <>
      <PageBody>
        <VStack gap="s-16">
          <HiringProcessSelector />
          <HiringProcessList />
        </VStack>
      </PageBody>
      <PageActions>
        <Button
          use={InternalLink}
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params),
              locationState,
            )
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          hideWhenNoChanges={false}
          successText="Job posting saved successfully"
          onAfterSubmit={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                id: values.id,
                specId: values.specialisation?.id,
              }),
            )
          }}
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
